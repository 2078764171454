<template>
  <StandardPageLayout>
    <router-link :to="{ name: 'blog' }" class="text-site-purple text-lg"><font-awesome-icon icon="fa-solid fa-arrow-left" /> Back to all posts</router-link>
    <p class="uppercase text-sm mt-10"><span class="bg-gray-300 rounded-full py-1 px-3">{{ date }}</span></p>
    <h1 class="text-4xl mt-2">{{ title }}</h1>
    <img :src="imageUrl" class="w-80 mt-10" />
    <ContentfulRTE :document="body" class="mt-10 text-lg" />
  </StandardPageLayout>
</template>
<script>
import { isoToMonthDayYear } from '../../../utils'

export default {
  props: ['slug'],
  data() {
    return {
      title: "",
      data: "",
      body: "",
      imageUrl: ""
    }
  },
  async created() {
    // this.loading = true
    try {
      const entriesData = await this.$contentful.getEntries({
        limit: 1,
        content_type: 'blogPost',
        'fields.slug': this.slug,
        include: 10
      })
      
      // this.loading = false
      if (entriesData.total === 0) {
        // redirect to blog
        return
      }

      const entry = entriesData.items[0]

      this.title = entry.fields.title
      this.date = isoToMonthDayYear(entry.sys.createdAt),
      this.body = entry.fields.body
      this.imageUrl = entry.fields.image.fields.file.url
    } catch (error) {
      // this.loading = false
      console.error(error)
    }
  },
}
</script>