export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'default'
    }
  },
  data() {
    return {
      termsLoading: false,
      noTerms: false,
      termsDocument: null
    }
  },
  created() {
    this.loadTerms()
  },
  methods: {
    async loadTerms() {
      this.termsLoading = true
      const entriesData = await this.$contentful.getEntries({
        limit: 1,
        content_type: 'setup',
        include: 10
      })

      if (entriesData.total === 0) {
        this.noTerms = true
        return
      }

      const setupEntry = entriesData.items[0]

      switch (this.type.toLowerCase()) {
        case 'lender':
          this.termsDocument = setupEntry.fields.lenderSetupTerms
          break
        case 'sms':
          this.termsDocument = setupEntry.fields.textingSetupTerms
          break
        case 'boss-assist':
          this.termsDocument = setupEntry.fields.bossAssistTerms
          break
        case 'tc-assist':
          this.termsDocument = setupEntry.fields.tcAssistTerms
          break
        case 'kathleen-black-ecosystem':
          this.termsDocument = setupEntry.fields.kathleenBlackCoachingEcosystemTerms
          break
        case 'default': // this used to be the redirect path param for fub-revamp
        case 'fub-revamp':
          this.termsDocument = setupEntry.fields.setupTerms
          break
      }
    }
  }
}