<template>
  <div v-html="rteHtml" class="contentful-rte-html" />
</template>
<script>
import { videoHtml } from '@/utils'
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import _get from 'lodash/get'

const docOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const targetData = _get(node, 'data.target')
      
      if (_get(targetData, 'sys.contentType.sys.id') !== 'video') {
        return ''
      }

      const videoId = targetData.fields.videoIdentifier

      const videoType = targetData.fields.type

      return videoHtml(videoId, videoType)
    }
  }
}

export default {
  props: ['document'],
  computed: {
    rteHtml() {
      return documentToHtmlString(this.document, docOptions)
    }
  }
}
</script>
<style>
.contentful-rte-html {
  & p {
    @apply leading-7 my-2;
  }
  & ul {
    @apply list-disc;

    & ul, ol {
      @apply ml-10;
    }
  }
  & ol {
    @apply list-decimal;

    & ol, ul {
      @apply ml-10;
    }
  }
  & h1 {
    @apply text-3xl my-5;
  }
  & h2 {
    @apply text-2xl my-4;
  }
  & h3 {
    @apply text-xl my-3;
  }
  & a {
    @apply text-site-purple underline cursor-pointer;
  }
  & li {
    & p {
      @apply my-0;
    }
  }
  & hr {
    @apply my-5;
  }
}
</style>