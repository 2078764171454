<template>
  <div v-if="show">
    <Modal @close="show = false">
      <div class="video-container mt-5">
        <iframe
        class="video"
        :src="videoUrl"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      </div>
    </Modal>
  </div>
</template>
<script>
import { DEMO_VIDEO } from '@/constants'

export default {
  props: {
    setupType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    videoUrl() {
      return `https://www.youtube.com/embed/${DEMO_VIDEO[this.setupType]}?autoplay=1`
    }
  },
  methods: {
    open() {
      this.show = true
    }
  }
}
</script>
<style scoped>
.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
