<template>
  <Modal>
    <div v-if="error" class="text-center">
      <p class="text-3xl text-site-purple font-semibold">Something went wrong...</p>
      <div>
        <p class="text-xl pt-5">There was an isssue submitting your information.</p>
        <p class="texl-lg text-gray-700 mt-5"><span class="bg-gray-200 p-1"><strong>Error Message:</strong> {{errorMessage}}</span></p>
        <p class="mt-5">If the error persists or you need our assistance please contact <MailToLink subject="Form Submission Failure" :body="errorMessage" /></p>
        <button class="button mt-5" @click="$emit('close')">Close</button>
      </div>
    </div>
    <div v-else-if="success" class="text-center">
      <slot name="success"></slot>
      <p v-if="receiptUrl" class="mt-5">Your payment receipt will be emailed to you. You may also view your payment receipt by <a :href="receiptUrl" target="_blank" class="link">clicking here</a>.</p>
      <p v-if="showNextSteps" class="mt-5">Thank you for your business!<br>You will receive a confirmation email from our team shortly.<br>We will provide a setup time frame as soon as possible, so be on the lookout for follow-up communication from our team.</p>
      <button class="button mt-5" @click="$router.replace(redirect ? redirect : '/')">Done</button>
    </div>
    <div v-else class="text-center">
      <p class="text-xl mb-5" v-if="noPayment">Hold tight! Your information is being submitted.</p>
      <p class="text-xl mb-5" v-else>Hold tight! Your information is being submitted and payment is processing.</p>
      <font-awesome-icon icon="fa-solid fa-spinner" spin class="text-3xl text-site-purple"/>
    </div>
  </Modal>
</template>
<script>
export default {
  props: ['errorMessage', 'loading', 'showNextSteps', 'noPayment', 'receiptUrl', 'redirect'],
  data() {
    return {
      success: false,
      error: false
    }
  },
  watch: {
    loading(newVal, oldVal) {
      if (oldVal && !newVal) {
        setTimeout(this.stopLoader, 1000)
      }
    },
  },
  methods: {
    stopLoader() {
      if (this.errorMessage) {
        this.error = true
      } else {
        this.success = true
      }
    },
  }
}
</script>