<template>
  <div class="bg-gray-100 min-h-screen flex flex-col">
    <!-- <header class="bg-gray-100 lg:fixed top-0 left-0 right-0 z-50 h-20 flex flex-col justify-center border-b border-gray-300"> -->
    <header class="bg-white relative px-5 lg:fixed top-0 inset-x-0 flex border-b border-gray-300 z-50 lg:h-20" :style="{marginTop: bannerOffset }">
      <!-- <div class="flex justify-between items-center container mx-auto"> -->
      <div class="max-w-7xl w-full mx-auto flex flex-col lg:flex-row justify-between items-center py-4">
        <Logo />
        <!-- <nav class="hidden md:flex items-center space-x-5"> -->
        <nav class="hidden md:flex items-center whitespace-nowrap mt-5 lg:mt-0 gap-5">
          <div v-scroll-to="{ el: '#whats-included', offset: -80 }" class="cursor-pointer hover:underline">What's Included</div>
          <div v-scroll-to="{ el: '#faq', offset: -80 }" class="cursor-pointer hover:underline">FAQ</div>
          <div v-scroll-to="{ el: '#pricing', offset: -80 }" class="cursor-pointer hover:underline">Pricing</div>
          <button @click="getStarted" class="button">Get Started</button>
        </nav>
      </div>
    </header>
    <div class="flex-grow lg:mt-20">
      <section class="site-container-outer bg-gray-200">
        <div class="site-continer-inner">
          <div class="flex justify-center">
            <div class="text-sm border-2 py-1 px-5 rounded-full border-gray-500 font-semibold">Kathleen Black + Kee Technology Solutions</div>
          </div>
          <div class="max-w-2xl mx-auto mt-10 text-center">
            <h1 class="text-4xl sm:text-5xl md:text-7xl font-semibold" style="line-height: 1.1;">Transform your<br><span class="text-site-purple">Follow Up Boss</span></h1>
            <h2 class="text-lg sm:text-xl md:text-2xl mt-10">Boost your Success with the <span class="font-semibold">Ultimate Ecosystem</span></h2>
          </div>
          <div class="flex flex-col md:flex-row justify-center mt-10 items-center gap-5">
            <button @click="getStarted" class="button">Get Started</button>
            <button @click="$refs.demo.open()" class="text-lg font-semibold text-site-purple">Learn More <font-awesome-icon icon="fa-solid fa-arrow-right" /></button>
            <Demo ref="demo" :setupType="setupType" />
          </div>
          <div class="flex justify-center">
            <div class="mt-10 border border-gray-400">
              <div class="flex items-center border-b border-gray-400 p-4">
                <font-awesome-icon icon="fa-solid fa-check" class="text-site-purple" />
                <div class="ml-5">
                  <h3 class="font-semibold">Lead Nurturing</h3>
                  <p class="text-sm font-light">Follow up protocols to drive conversions</p>
                </div>
              </div>
              <div class="flex items-center border-b border-gray-400 p-4">
                <font-awesome-icon icon="fa-solid fa-check" class="text-site-purple" />
                <div class="ml-5">
                  <h3 class="font-semibold">Beyond Closing</h3>
                  <p class="text-sm font-light">Follow up protocols for COI & VIP COI</p>
                </div>
              </div>
              <div class="flex items-center border-b border-gray-400 p-4">
                <font-awesome-icon icon="fa-solid fa-check" class="text-site-purple" />
                <div class="ml-5">
                  <h3 class="font-semibold">Accountability</h3>
                  <p class="text-sm font-light">Ensure protocol adherence</p>
                </div>
              </div>
              <div class="flex items-center p-4">
                <font-awesome-icon icon="fa-solid fa-check" class="text-site-purple" />
                <div class="ml-5">
                  <h3 class="font-semibold">Comprehensive Checklists</h3>
                  <p class="text-sm font-light">Streamline your workflow and transactions</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="site-container-outer bg-gray-100">
        <div class="site-container-inner">
          <p class="uppercase text-site-purple font-semibold text-center">Benefits</p>
          <p class="text-center text-4xl mt-5 font-bold">This powerful ecosystem will...</p>
          <div v-for="benefit, idx in benefits" :key="`benefit_${idx}`" class="p-10 flex items-center" :class="{ 'border-t border-kts-gold': idx > 0 }">
            <div class="flex-grow">
              <div class="text-kts-gold text-5xl font-bold">{{ idx + 1 }}</div>
              <h3 class="mt-4 text-3xl font-semibold">{{ benefit.title }}</h3>
              <p class="mt-4 text-lg">{{ benefit.body }}</p>
            </div>
            <div class="w-28 ml-14 flex-shrink-0 hidden md:block">
              <img :src="require(`@/assets/images/${benefit.image}`)" />
            </div>
          </div>
        </div>
      </section>
      <section class="site-container-outer bg-gray-200">
        <div class="site-container-inner">
          <h3 class="text-3xl text-center font-semibold">Have any of these pain points?</h3>
          <div class="bg-white text-black p-5 max-w-xl mt-10 mx-auto shadow-lg text-sm">
            <div v-for="(item, idx) in painPoints" :key="`pain_point_${idx}`" class="flex items-center py-3" :class="{ 'border-t border-gray-200': idx > 0 }">
              <div class="w-1 h-1 bg-site-purple rounded-full flex items-center justify-center flex-shrink-0">
                <!-- <font-awesome-icon icon="fa-solid fa-exclamation" class="text-white text-sm" /> -->
              </div>
              <p class="ml-4">{{ item }}</p>
            </div>
          </div>
          <p class="mt-10 text-lg">Whether you are new to Follow Up Boss or a veteran user, if you are looking for a curated and intuitive approach to using your CRM then you’ve come to the right place. We’re here to help ensure you’re utilizing Follow Up Boss to its fullest potential. We offer a thorough setup comprised of smart lists, action plans, and automations that provide a simplified workflow and enable you to stay focused on what you do best: creating relationships and closing deals.</p>
        </div>
      </section>
      <section class="site-container-outer bg-gray-100" id="whats-included">
        <div class="site-container-inner">
          <h2 class="text-3xl text-center font-semibold">What's Included</h2>
          <div class="mt-10">
            <div v-for="(section, idx) in includedSections" :key="`included_${idx}`" class="py-10" :class="{ 'border-b border-gray-300': idx < includedSections.length - 1 }">
              <div class="flex flex-col items-center">
                <h3 class="text-2xl font-semibold my-5 text-kts-gold">{{ section.title }}</h3>
              </div>
              <p v-if="section.blurb" class="mb-4">{{ section.blurb }}</p>
              <ul class="space-y-4">
                <li v-for="(item, itemIdx) in section.bulletList" :key="`bullet_${itemIdx}`" class="flex items-start">
                  <div class="w-1.5 h-1.5 bg-kts-gold rounded-full mt-2.5 flex-shrink-0"></div>
                  <span class="ml-4" v-html="item"></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="site-container-outer bg-gray-200" id="faq">
        <div class="site-container-inner">
          <h2 class="text-3xl text-center font-semibold">Frequently Asked Questions</h2>
          <div class="mt-10 space-y-4">
            <div v-for="(faq, idx) in faqs" :key="`faq_${idx}`">
              <div @click="toggleFaq(idx)" class="w-full text-left py-2 flex justify-between items-center hover:text-site-purple transition-colors select-none cursor-pointer">
                <h3 class="font-semibold text-lg">{{ faq.question }}</h3>
                <font-awesome-icon :icon="faq.isOpen ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'" />
              </div>
              <div v-show="faq.isOpen">
                <ContentfulRTE :document="faq.answer" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="site-container-outer" id="pricing">
        <div class="site-container-inner">
          <p class="uppercase text-site-purple font-semibold text-center">Pricing</p>
          <h2 class="text-2xl text-center mt-5 max-w-xl mx-auto">Get the power of the Kathleen Black Follow Up Boss Ecosystem for <strong>FREE!</strong></h2>
          <div class="mt-10 border-2 border-site-purple bg-white p-8 shadow-xl text-center">
            <!-- <p class="text-3xl font-semibold">Free</p> -->
            <p class="text-lg font-medium">This done-for-you Follow Up Boss setup is included in your coaching package!</p>
            <p class="text-sm font-light text-gray-700 mt-2">* Approval is required from Kathleen Black Coaching after you sign up</p>
            <ul class="text-left space-y-4 mt-8 inline-block">
              <li class="flex items-center">
                <font-awesome-icon icon="fa-solid fa-check" class="text-site-purple" />
                <span class="ml-3">Lead Nurturing Protocols</span>
              </li>
              <li class="flex items-center">
                <font-awesome-icon icon="fa-solid fa-check" class="text-site-purple" />
                <span class="ml-3">Beyond Closing Follow-up System</span>
              </li>
              <li class="flex items-center">
                <font-awesome-icon icon="fa-solid fa-check" class="text-site-purple" />
                <span class="ml-3">Accountability Framework</span>
              </li>
              <li class="flex items-center">
                <font-awesome-icon icon="fa-solid fa-check" class="text-site-purple" />
                <span class="ml-3">Comprehensive Checklists</span>
              </li>
            </ul>
            <div class="bg-gray-100 py-10 px-5 my-10">
              <p class="text-2xl font-medium">Ecosystem + Power Up</p>
              <div class="flex justify-center">
                <p class="text-site-purple mt-2 font-bold rounded-full">Optional Add-On</p>
              </div>
              <p class="mt-5">Optionally get the Kathleen Black Ecosystem paired with<br>Kee Technology Solutions’ <strong>Automated Drip Campaigns</strong>!</p>
              <p class="mt-5">Receive automated email campaigns that seamlessly complement your active nurturing efforts with empathetic and personable messages that drive engagement and generate responses.</p>
              <a class="mt-5 link inline-block" @click="showPowerUpLearnMore = true">Learn more</a>
              <p class="font-semibold text-lg mt-10">Base Price for Ecosystem + Power Up</p>
              <p class="text-2xl mt-2">${{ pricing.BASE.toLocaleString('en') }}</p>
              <p class="mt-5 text-sm text-gray-700">* Optional Add-Ons to this power up available at signup</p>
            </div>
            <p class="mt-8 font-medium text-lg text-center">Just some basic info is required to get the ball rolling!</p>
            <button @click="getStarted" class="button mt-8">Get Started</button>
          </div>
        </div>
      </section>
    </div>
    <footer class="p-5 text-center font-medium">© {{$copyrightDate}} by Kee Technology Solutions LLC</footer>
    <PowerUpModal v-if="showPowerUpLearnMore" @close="showPowerUpLearnMore = false" />
    <SignUpForm ref="signUp" :setupType="setupType" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { SETUP_TYPE } from '@/constants'
import { getPricing } from '@/pricing'
import PowerUpModal from './PowerUpModal.vue'
import SignUpForm from '@/components/common/landing/SignUpForm.vue'
import Demo from '@/components/common/landing/Demo.vue'

export default {
  components: {
    PowerUpModal,
    SignUpForm,
    Demo
  },
  data() {
    return {
      setupType: SETUP_TYPE.KATHLEEN_BLACK_ECOSYSTEM,
      showPowerUpLearnMore: false,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      includedSections: [
        {
          title: "Follow Up Protocols",
          bulletList: [
            "<strong>New Lead Plan for Field Agent or ISA/Lead Conversion Partner</strong> - Call cadence, frequency that an agent/ISA is expected to call a new lead within the first 30 days",
            "<strong>Lead Plan Follow Up & Lead Plan Protocol</strong> - Frequency of calls to attempt to reengage a buyer or seller until they are ready to book an appointment",
            "<strong>Booked Appt LCP/Field Agent</strong> - Tasks for Lead Conversion Partner or Field Agent once a buyer or listing appointment is booked for a lead agent",
            "<strong>Missed Appointment</strong> - Frequency of calls to attempt to reengage and rebook a missed buyer or seller appointment.",
            "<strong>Closing Follow Up</strong> - Call tasks once a client is moved into the Past & Future Client Community stage. Lasts 20 years",
            "<strong>VIP COI</strong> - Call cadence for VIP COI past clients, SOI, those identified as highly likely to repeat, refer, or influence others when we show them extra love. This is the protocol for the top 5 to 10% of your database per lead agent. Lasts 20 years"
          ]
        },
        {
          title: "Accountability Automations",
          bulletList: [
            "<strong>New Lead Accountability</strong> - Multi-layered, multi-step process to keep your agents accountable. If expectations are not met, the lead is reassigned to the pond for other agents to access the lead, the previous agent is notified about the loss of the lead, and the team lead/manager is notified",
            "<strong>Lead Follow Up/Missed Appointment Accountability</strong> - Multi-layered, multi-step process to keep your agents accountable. If expectations are not met, the lead is reassigned to the pond for other agents to access the lead, the previous agent is notified about the loss of the lead, and the team lead/manager is notified",
            "<strong>Closing Follow Up Accountability</strong> - The assigned agent will be alerted that they missed their closing follow up and that the team leader/manager will be alerted",
            "<strong>VIP COI Accountability</strong> - The assigned agent will be alerted that they missed their closing follow up and that the team leader/manager will be alerted"
          ]
        },
        {
          title: "Transactional Checklists",
          bulletList: [
            "<strong>Buyer Plan</strong> - Tasks for operations when new buyer Buyer Representation paperwork is submitted. As well as best practice tasks and call cadence for lead agents to guide buyers with instructions per step.",
            "<strong>Buyer Accepted Checklist</strong> - Tasks for lead agent and operations that must be completed when a buyer offer is accepted, including reminders for paperwork required to firm up or release the deal.",
            "<strong>Buyer Firm Checklist</strong> - Tasks for lead agent and operations that must be completed when a buyer offer is confirmed to be firm.",
            "<strong>Buyer Closed Checklist</strong> - Tasks for lead agent and operations that must be completed when a buyer purchase is confirmed as closed by the lawyer",
            "<strong>Postponement Plan</strong> - Buyer Plan to be used when buyer is reactivated after their original plan was put on hold or after an expired agreement leads to a new buyer agreement being signed, and this plan is activated for lead agent call and face to face cadence.",
            "<strong>Seller New Listing Checklist</strong> - Tasks for operations when listing  paperwork is signed and submitted to operations by the lead agent. As well as, best practise tasks and call cadence for the lead agent.",
            "<strong>Seller Accepted Checklist</strong> - Tasks for operations when new listing agreement paperwork is submitted. As well as, best practice tasks and call cadence for lead agent.",
            "<strong>Seller Firm Checklist</strong> - Tasks for operations when listing sale is confirmed firm with paperwork submitted by the lead agent. As well as, best practise tasks and call cadence for the lead agent.",
            "<strong>Seller Closed Checklist</strong> - Tasks for operations when listing sale is confirmed closed by the lawyer. As well as, best practise tasks and call cadence for the lead agent.",
            "<strong>Relist Checklist</strong> - Tasks for operations and lead agent when an active listing is terminated and re-listed back onto the market."
          ]
        },
        {
          title: "Stages, Definitions, and Lead Flows",
          bulletList: [
            "Provide new, simplified stages from Kathleen Black Coaching, define your new stages in your FUB account to help simplify your processes, collaborate with client to merge previous stages.",
            "Set up lead flows to ensure all new lead sources have the correct action plan assigned."
          ]
        },
        {
          title: "Smart Lists",
          blurb: "There are a total of ten smart lists arranged by importance. These smart lists update automatically based on the filters set up.",
        },
        {
          title: "Training",
          blurb: "After the setup is complete, we provide the following training:",
          bulletList: [
            "One-on-one personalized implementation call with a customer success manager",
            "One additional 30-minute training call included at no extra cost",
            "Two ambassador seats with access to bi-monthly interactive group training sessions in the Kathleen Black Community, hosted by a Kee Technology Customer Success Manager (sessions are recorded)",
            "Access to our comprehensive video training library for in-depth learning",
            "A detailed cheat sheet for quick reference on setup components and daily Follow Up Boss activities"
          ]
        }
      ],
      faqs: [
        // {
        //   question: "Do these action plans get applied to my existing database?",
        //   answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
        //   isOpen: false
        // },
        // {
        //   question: "Is there anything I should do prior to signing up?",
        //   answer: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.",
        //   isOpen: false
        // },
        // {
        //   question: "What are your recommended stages so I can prepare?",
        //   answer: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis autem vel eum iure reprehenderit qui in ea voluptate.",
        //   isOpen: false
        // },
        // {
        //   question: "What if I'm under a broker account?",
        //   answer: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est.",
        //   isOpen: false
        // },
        // {
        //   question: "Do you work with other CRMs?",
        //   answer: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate.",
        //   isOpen: false
        // }
      ]
    }
  },
  async created() {
    const entriesData = await this.$contentful.getEntries({
      limit: 1,
      content_type: 'setupType',
      'fields.code': this.setupType,
      include: 10
    })

    console.log(entriesData.items[0])
    const faqs = entriesData.items[0].fields.faqList.map(faq => {
      const { question, answer } = faq.fields
      return {
        question,
        answer,
        isOpen: false
      }
    })
    this.faqs = faqs
  },
  computed: {
    ...mapGetters(['bannerOffset']),
    pricing() {
      return getPricing(this.setupType)
    },
    isDisabled() {
      return !this.firstName || !this.lastName || !this.email || !this.phone
    },
    benefits() {
      return [
        {
          title: "Provide you with proven protocols",
          body: 'Follow up protocols for new leads, nurturing, Past and future clients, COI, and VIP COI',
          image: "conversations.svg"
        },
        {
          title: "Keep your agents accountable",
          body: "Worry less about leads falling through the cracks with a built in accountability system - fully automated.",
          image: "search.svg"
        },
        {
          title: "Provide you with processes for transactions",
          body: 'Our automations allow you to quickly ensure leads are in the right smart lists for your daily calling and on the right action plan to supplement your efforts and provide value to your leads and clients.',
          image: "workflow.svg"
        },
      ]
    },
    painPoints() {
      return [
        "I have a CRM, but I'm not really using it",
        "I have some action plans, but they're not performing", 
        "I don't have the time to create drip campaigns",
        "I struggle with finding which leads to call",
        "My agents are not following proper protocols",
        "I don't have standard operating procedures",
        "I feel like I've been going down rabbit holes",
        "I'm worried I'll break something"
      ]
    },
  },
  methods: {
    toggleFaq(index) {
      this.faqs = this.faqs.map((faq, idx) => ({
        ...faq,
        isOpen: idx === index ? !faq.isOpen : false
      }));
    },
    getStarted() {
      this.$refs.signUp.open()
    },
  }
}
</script>
