<template>
  <StandardPageLayout transparentMain="true">
    <div class="text-center text-white">
      <h1 class="text-3xl font-semibold mt-5">See what's new</h1>
      <h2 class="text-xl mt-5 font-medium">Kee Technology Solution Changelog</h2>
      <p class=text-kts-gold>New offerings, improvements, and more!</p>
    </div>
    <div v-for="update in updates" :key="update.id" class="bg-white rounded max-w-3xl mx-auto p-5 mt-10">
      <p class="text-sm">{{ update.date }}</p>
      <h2 class="text-2xl font-semibold mt-2">{{ update.title }}</h2>
      <div class="mt-5">
        <ContentfulRTE :document="update.body" />
      </div>
    </div>
  </StandardPageLayout>
</template>

<script>
export default {
  data() {
    return {
      updates: []
    }
  },
  async created() {
    const entries = await this.$contentful.getEntries({
      content_type: 'update',
      include: 10
    })

    this.updates = entries.items.map(item => {
      return {
        title: item.fields.title,
        body: item.fields.body,
        date: new Date(item.sys.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
      }
    })
  }
}
</script>