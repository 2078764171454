<template>
  <section>
    <div v-for="({ question, answer }, idx) in faqList" :key="`faq_${idx}`" class="mt-10">
      <div class="py-2 font-semibold text-lg border-b">{{ question }}</div>
      <ContentfulRTE :document="answer" class="mt-5" />
    </div>
  </section>
</template>
<script>
export default {
  props: {
    faqList: {
      type: Array,
      require: true
    }
  }
}
</script>