<template>
  <section class="py-20 px-6 text-white" id="service">
    <div class="container mx-auto">
      <h3 class="text-center text-4xl font-bold">What's Included</h3>
      <div class="mt-20 bg-white text-black p-10 rounded-xl">
        <div v-for="(section, index) in whatsIncludedSections" :key="section.title" :class="{'border-b border-gray-200 pb-10 mb-10': index !== whatsIncludedSections.length - 1}">
          <div class="flex flex-col items-center">
            <img :src="section.graphicUrl" width="100px" />
            <h4 class="text-2xl font-semibold underline mt-5">{{ section.title }}</h4>
          </div>
          <ContentfulRTE :document="section.body" class="mt-5" />
          <div v-if="section.showMoreBody">
            <a class="inline-block text-site-purple font-semibold mt-5 cursor-pointer select-none"
              @click="section.isExpanded = !section.isExpanded">
              Read {{ section.isExpanded ? 'Less' : 'More' }}
              <font-awesome-icon :icon="`fa-solid fa-chevron-${section.isExpanded ? 'up' : 'down'}`" />
            </a>
          </div>
          <ContentfulRTE v-if="section.isExpanded" :document="section.showMoreBody" class="mt-5" />
        </div>
      </div>
      <h3 class="text-center text-4xl font-bold mt-20">Add-Ons</h3>
      <h4 class="text-center text-2xl font-medium mt-5">Take it to the next level with add-ons</h4>
      <div class="mt-10 bg-white text-black p-10 rounded-xl">
        <div v-for="(addOn, index) in addOnSections" :key="addOn.title" :class="{'border-b border-gray-200 pb-10 mb-10': index !== addOnSections.length - 1}">
          <h4 class="text-xl font-semibold mt-5 text-center">{{ addOn.title }} - ${{ addOn.price }}</h4>
          <ContentfulRTE :document="addOn.shortDescription" class="mt-5" />
          <div v-if="addOn.longDescription">
            <a class="inline-block text-site-purple font-semibold mt-5 cursor-pointer select-none"  
              @click="addOn.isExpanded = !addOn.isExpanded">
              Read {{ addOn.isExpanded ? 'Less' : 'More' }}
              <font-awesome-icon :icon="`fa-solid fa-chevron-${addOn.isExpanded ? 'up' : 'down'}`" />
            </a>
            <ContentfulRTE v-if="addOn.isExpanded" :document="addOn.longDescription" class="mt-5" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { SETUP_TYPE } from '@/constants'
import { getPricing } from '@/pricing'

export default {
  props: {
    setupType: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(SETUP_TYPE).includes(value)
      },
    }
  },
  data() {
    return {
      whatsIncludedSections: [],
      addOnSections: []
    }
  },
  async created() {
    const entriesData = await this.$contentful.getEntries({
      limit: 1,
      content_type: 'setupType',
      'fields.code': this.setupType,
      include: 10
    })

    this.whatsIncludedSections = entriesData.items[0].fields.whatsIncludedSections.map(section => {
      const { title, graphic, body, showMoreBody } = section.fields
      return {
        title,
        graphicUrl: `https:${graphic.fields.file.url}`,
        body,
        showMoreBody,
        isExpanded: false
      }
    })

    this.addOnSections = entriesData.items[0].fields.addOnSections.map(section => {
      const { code, title, shortDescription, longDescription } = section.fields
      return {
        title,
        price: this.pricing[code],
        shortDescription,
        longDescription,
        isExpanded: false
      }
    })
  },
  computed: {
    pricing() {
      return getPricing(this.setupType)
    }
  }
}
</script>
