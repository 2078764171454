import { getPricing } from '@/pricing'

export default {
  computed: {
    pricing() {
      return getPricing(this.setupType, this.legacyPricing)
    },
    legacyPricing() {
      return this.$route.query.legacyPricing || ''
    },
    lineItems() {
      let lineItems = []
      lineItems.push({
        name: 'Base Price',
        price: this.pricing.BASE
      })

      if (this.customizeStages) {
        lineItems.push({
          name: 'Stage Customization (default stage usage opt-out)',
          price: this.pricing.CUSTOM_STAGES
        })
      }

      if (this.formModel.includeSMSDrips) {
        lineItems.push({
          name: 'SMS Drips',
          price: this.pricing.SMS_DRIPS
        })

        if (this.formModel.customizeStages) {
          lineItems.push({
            name: 'Stage Customization on SMS Drips (default stage usage opt-out)',
            price: this.pricing.SMS_DRIPS_CUSTOM_STAGES
          })
        }
      }

      if (this.formModel.includeSpanishActionPlans) {
        lineItems.push({
          name: 'Spanish Action Plans',
          price: this.pricing.SPANISH_ACTION_PLANS
        })
      }

      if (this.formModel.includeLenderAddOn) {
        lineItems.push({
          name: 'Lender Add-on',
          price: this.pricing.LENDER_ADD_ON
        })
      }

      if (this.formModel.customWorkAmount) {
        lineItems.push({
          name: 'Custom Work Amount',
          price: this.customWorkAmount
        })
      }

      if (this.formModel.discountAmount) {
        lineItems.push({
          name: 'Discount Amount',
          price: -this.discountAmount
        })
      }

      if (this.formModel.includeRevampExtended) {
        lineItems.push({
          name: 'Revamp Extended',
          price: this.pricing.REVAMP_EXTENDED
        })
      }

      if (this.formModel.includePondsAddOn) {
        lineItems.push({
          name: 'Ponds Add-On',
          price: this.pricing.PONDS_ADD_ON
        })
      }

      if (this.formModel.includeRealScoutActionPlans) {
        lineItems.push({
          name: 'RealScout Action Plans',
          price: this.pricing.PLATFORM_PAIRING_ADD_ON
        })
      }

      if (this.formModel.includeFelloActionPlans) {
        lineItems.push({
          name: 'Fello Action Plans',
          price: this.pricing.PLATFORM_PAIRING_ADD_ON
        })
      }

      if (this.formModel.includeRuusterActionPlans) {
        lineItems.push({
          name: 'Ruuster Action Plans',
          price: this.pricing.PLATFORM_PAIRING_ADD_ON
        })
      }

      if (this.formModel.includeConversionMonsterActionPlans) {
        lineItems.push({
          name: 'Conversion Monster Action Plans',
          price: this.pricing.PLATFORM_PAIRING_ADD_ON
        })
      }

      if (this.formModel.includeYlopoActionPlans) {
        lineItems.push({
          name: 'Ylopo Action Plans',
          price: this.pricing.PLATFORM_PAIRING_ADD_ON
        })
      }

      if (this.formModel.includePrivateTeamTrainingCall) {
        lineItems.push({
          name: 'Private Team Training Call',
          price: this.pricing.PRIVATE_TEAM_TRAINING_CALL
        })
      }

      return lineItems
    },
    total() {
      return this.lineItems.reduce((accumulator, currentItem) => accumulator + currentItem.price, 0);
    },
    depositAmount() {
      return this.total/2
    },
    totalDollarAmount() {
      return `$${this.total.toLocaleString('en')}`
    },
  }
}