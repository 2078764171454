<template>
  <FormPageLayout>
    <button v-if="isDev" class="button my-10" @click="autoFillForm">Auto Fill Form</button>
    <p class="text-2xl font-semibold">Thanks for signing up!</p>
    <p class="text-lg mt-2">We will need the following information before we can start revamping your FUB.</p>
    <!-- Basic Info -->
    <FormSection v-if="!keeId" title="Your Info">
      <VTextField :v="v$.formModel.basicInfo.firstName" placeholder="First Name"/>
      <VTextField :v="v$.formModel.basicInfo.lastName" placeholder="Last Name"/>
      <VTextField :v="v$.formModel.basicInfo.email" placeholder="Email"/>
      <VTextField :v="v$.formModel.basicInfo.phone" placeholder="Phone Number"/>
    </FormSection>
    <!-- Follow Up Boss Credentials -->
    <FormSection title="Follow Up Boss Credentials" helperText="(Owner Account)">
      <VTextField :v="v$.formModel.crmUsername" placeholder="Username" autocomplete="off" />
      <VTextField :v="v$.formModel.crmPassword" placeholder="Password" autocomplete="off" />
    </FormSection>
    <!-- Website URL(s) -->
    <FormSection title="Website URL">
      <div v-for="(website, idx) in formModel.websites" :key="`website_${idx}`">
        <input type="text" v-model="website.url" class="form-input" placeholder="http://mywebsite.com" />
        <font-awesome-icon v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeWebsite(idx)" icon="fa-solid fa-times" />
      </div>
      <p class="mt-5 font-semibold text-sm">Have multiple websites?</p>
      <a @click="addWebsite" class="text-site-purple font-semibold mt-1 inline-block underline cursor-pointer">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Website
      </a>
    </FormSection>
    <!-- Online Reviews -->
    <FormSection title="Online Reviews" helperText="(Please provide any URLs to your online reviews i.e Google, Zillow, Facebook etc.)">
      <div v-for="(onlineReview, idx) in formModel.onlineReviews" :key="`online_review_${idx}`">
        <input type="text" v-model="onlineReview.url" class="form-input" placeholder="http://review-url.com" />
        <font-awesome-icon v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeOnlineReview(idx)" icon="fa-solid fa-times" />
      </div>
      <p class="mt-5 font-semibold text-sm">Have more online review URLs?</p>
      <a @click="addOnlineReview" class="text-site-purple font-semibold mt-1 inline-block underline cursor-pointer">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Online Review URL
      </a>
    </FormSection>
    <!-- Brokerage -->
    <FormSection title="Brokerage">
      <VTextField :v="v$.formModel.brokerage" placeholder="i.e Keller Williams Realty" />
    </FormSection>
    <!-- Business Address -->
    <FormSection title="Business Address">
      <VTextField :v="v$.formModel.businessAddress.addressLine1" placeholder="Address Line 1" />
      <VTextField :v="v$.formModel.businessAddress.addressLine2" placeholder="Address Line 2" />
      <CountrySelect
        v-model="formModel.businessAddress.country"
        :errorMessage="v$.formModel.businessAddress.country.$error ? v$.formModel.businessAddress.country.$errors[0].$message : ''"
      />
      <VTextField :v="v$.formModel.businessAddress.city" placeholder="City" />
      <StateSelect
        v-model="formModel.businessAddress.state"
        :errorMessage="v$.formModel.businessAddress.state.$error ? v$.formModel.businessAddress.state.$errors[0].$message : ''"
        :showProvinces="formModel.businessAddress.country === 'Canada'"
      />
      <VTextField :v="v$.formModel.businessAddress.zip" :placeholder="formModel.businessAddress.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
    </FormSection>
    <!-- Servicing Multiple Markets -->
    <FormSection title="Do you service more than one market?" helperText="(multiple states, provinces, or regions)">
      <VRadio :v="v$.formModel.multiMarketServicing" />
    </FormSection>
    <!-- YouTube Channel -->
    <FormSection title="YouTube Channel">
      <VTextField :v="v$.formModel.youtube" placeholder="YouTube Channel URL" />
    </FormSection>
    <!-- BombBomb -->
    <FormSection title="Do you have BombBomb videos?">
      <VRadio :v="v$.formModel.hasBombBombVideos" />
    </FormSection>
    <FormSection v-if="formModel.hasBombBombVideos === 'Yes'" title="BombBomb Credentials">
      <VTextField :v="v$.formModel.bombbombUsername" placeholder="Username" autocomplete="off" />
      <VTextField :v="v$.formModel.bombbombPassword" placeholder="Password" autocomplete="off" />
    </FormSection>
    <!-- RealScout -->
    <FormSection title="Include RealScout Action Plans?" :helperText="`(Additional $${pricing.PLATFORM_PAIRING_ADD_ON.toLocaleString('en')})`" chipText="RealScout - Platform Automations Add-On">
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using RealScout</p>
      <ToggleSwitch v-model="formModel.includeRealScoutActionPlans" />
    </FormSection>
    <!-- Fello -->
    <FormSection title="Include Fello Action Plans?" :helperText="`(Additional $${pricing.PLATFORM_PAIRING_ADD_ON.toLocaleString('en')})`" chipText="Fello - Platform Automations Add-On">
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Fello</p>
      <ToggleSwitch v-model="formModel.includeFelloActionPlans" />
    </FormSection>
    <!-- Ruuster -->
    <FormSection title="Include Ruuster Action Plans?" :helperText="`(Additional $${pricing.PLATFORM_PAIRING_ADD_ON.toLocaleString('en')})`" chipText="Ruuster - Platform Automations Add-On">
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Ruuster</p>
      <ToggleSwitch v-model="formModel.includeRuusterActionPlans" />
    </FormSection>
    <!-- Conversion Monster -->
    <FormSection title="Include Conversion Monster Action Plans?" :helperText="`(Additional $${pricing.PLATFORM_PAIRING_ADD_ON.toLocaleString('en')})`" chipText="Conversion Monster - Platform Automations Add-On">
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Conversion Monster</p>
      <ToggleSwitch v-model="formModel.includeConversionMonsterActionPlans" />
    </FormSection>
    <!-- Ylopo Users -->
    <FormSection title="Include Ylopo Action Plans?" :helperText="`(Additional $${pricing.PLATFORM_PAIRING_ADD_ON.toLocaleString('en')})`" chipText="Ylopo - Platform Automations Add-On">
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Ylopo</p>
      <ToggleSwitch v-model="formModel.includeYlopoActionPlans" />
    </FormSection>
    <!-- Ylopo Users - Yes - Search Site Live -->
    <div v-if="formModel.includeYlopoActionPlans">
      <FormSection title="Is your Ylopo search site live?">
        <VRadio :v="v$.formModel.ylopoUserSearchSiteLive" />
      </FormSection>
      <FormSection title="Is your Ylopo branded website live?">
        <VRadio :v="v$.formModel.ylopoUserBrandedSiteLive" :options="['Yes', 'No', 'Not using']" />
      </FormSection>
      <FormSection v-if="formModel.ylopoUserBrandedSiteLive === 'Yes'" title="Ylopo branded website URL">
        <VTextField :v="v$.formModel.ylopoUserBrandedSiteUrl" placeholder="URL" autocomplete="off" />
      </FormSection>
    </div>
    <FormSection title="Is the Follow Up Boss Pixel installed on your website?">
      <VRadio :v="v$.formModel.fubPixelInstalled" :options="['Yes', 'No', 'Not compatible']" />
    </FormSection>
    <FormSection v-if="formModel.fubPixelInstalled === 'No' && !formModel.includeYlopoActionPlans" class="inline-block" title="Courtesy service for installing the Follow Up Boss Pixel">
      <p class="text-sm text-gray-700">If you have a website backend and would like us to attempt the installation of the Follow Up Boss pixel for you, please provide your site's credentials below.<br><br><strong>Please note</strong> we offer this install service as a courtesy only and is not an objective step in the FUB revamp service we provide.<br><br><strong>Please note</strong> not every website is compatible with the Follow Up Boss Pixel and some site providers even reject the install of 3rd party scripts. Often we don't know compatibility issues until we attempt to install.<br><br>If you prefer to install the FUB pixel yourself you can refer to <a class="link" href="https://help.followupboss.com/hc/en-us/sections/1500001949321-Follow-Up-Boss-Pixel" target="_blank">Follow Up Boss Pixel documentation</a></p>
      <div v-for="(websiteBackend, idx) in formModel.websiteBackends" :key="`website_backend_${idx}`" class="bg-gray-100 p-5 mt-2">
        <p v-if="idx !== 0" class="cursor-pointer" @click="removeBackend(idx)"><font-awesome-icon icon="fa-solid fa-times" /> Remove</p>
        <input type="text" v-model="websiteBackend.url" class="form-input" placeholder="Backend URL" />
        <br>
        <input type="text" v-model="websiteBackend.username" class="form-input" placeholder="Username" />
        <br>
        <input type="text" v-model="websiteBackend.password" class="form-input" placeholder="Password" />
      </div>
      <p class="mt-5 font-semibold text-sm">Need the FUB pixel added to multiple websites?</p>
      <a @click="addBackend" class="text-site-purple font-semibold mt-1 inline-block underline cursor-pointer">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Website
      </a>
    </FormSection>
    <!-- Stage setup -->
    <FormSection title="Setting up stages for the revamp">
      <a class="link underline text-base font-normal" @click="showStagesModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see stage definitions</a>
      <div class="field" :class="{ 'field-block-error': v$.formModel.stageSetup.$error }">
        <p v-if="v$.formModel.stageSetup.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.stageSetup.$errors[0].$message }}
        </p>
        <input type="radio" :value="stageSetupTypes.DEFAULT" v-model="formModel.stageSetup" />
        <label>Use the default stages provided by the revamp (recommended for most)</label>
        <br />
        <input type="radio" :value="stageSetupTypes.ZILLOW_TWO_WAY_SYNC" v-model="formModel.stageSetup" />
        <label>I want to use the Zillow Stages via the 2-way sync with Zillow - more info <a class ="link" href="https://help.followupboss.com/hc/en-us/articles/8895957403927" target="_blank">here</a></label>
        <br />
        <input type="radio" :value="stageSetupTypes.CUSTOM" v-model="formModel.stageSetup" />
        <label>I would like to opt out of using the default stages used by the revamp. Instead, I have a requirement that the revamp be customized to use predefined stages that I will provide to Kee Technology Solutions. I acknowledge that I must provide names of my stages before work on the revamp can be started and <strong>I agree to pay a ${{ pricing.CUSTOM_STAGES }} customization fee.</strong> I acknowledge that my review is required before going live with the revamp. (Note: We will reach out to you after your submission to obtain a list of stages we can use. We will reach back out after the setup is complete to review the setup with you before we go live with it.)</label>
      </div>
      <StagesModal v-if="showStagesModal" @close="showStagesModal = false" />
    </FormSection>
    <!-- SMS Add On -->
    <FormSection title="Include add-on SMS Drips?" :helperText="`(Additional $${pricing.SMS_DRIPS.toLocaleString('en')})`" chipText="SMS Add-On">
      <p class="mt-2 text-sm font-medium">If you currently use either <strong>Texting Betty</strong>, <strong>Leadngage</strong>, or <strong>StreetText</strong> for automated texting in FUB, we can add our SMS Drips to the setup we provide for an additional ${{ pricing.SMS_DRIPS }}.</p>
      <p class="mt-2 text-sm font-medium">Note: You can always sign up for this add-on at a later time if, in the future, you sign up for either Texting Betty, Leadngage or StreetText.</p>
      <p class="mt-2 text-sm font-medium">By making this selection you agree that you <strong>currently</strong> are using either <strong>Texting Betty</strong>, <strong>Leadngage</strong>, or <strong>StreetText</strong>.</p>
      <ToggleSwitch v-model="formModel.includeSMSDrips" />

      <FormSection v-if="formModel.includeSMSDrips" :embed="true" title="Texting automation service you use">
        <VRadio :v="v$.formModel.textingService" :options="['Texting Betty', 'Leadngage', 'StreetText']" />
      </FormSection>
    </FormSection>
    <!-- Revamp Extended Add-on -->
    <FormSection title='Include add-on "Revamp Extended"?' :helperText="`(Additional $${pricing.REVAMP_EXTENDED.toLocaleString('en')})`" chipText="Revamp Extended Add-On">
      <p class="link underline text-base font-normal" @click="showRevampExtendedModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see what's included</p>
      <ToggleSwitch v-model="formModel.includeRevampExtended" />
      <Modal v-if="showRevampExtendedModal" @close="showRevampExtendedModal = false">
        <RevampExtended crm="Follow Up Boss" :price="pricing.REVAMP_EXTENDED.toLocaleString('en')" />
      </Modal>
    </FormSection>
    <!-- Ponds Add-on -->
    <FormSection title='Include Ponds add-on?' :helperText="`(Additional $${pricing.PONDS_ADD_ON.toLocaleString('en')})`" chipText="Ponds Add-On">
      <p>9 best practice ponds w/ cooresponding action plans for managing new leads, re-engagements, re-inquiries, resurrections, vendors, off markets, and referral agents. <strong class="text-sm">(only available to FUB accounts that have more than one user)</strong></p>
      <ToggleSwitch v-model="formModel.includePondsAddOn" />
    </FormSection>
    <!-- Spanish Content -->
    <FormSection title="Include add-on Spanish Action Plans?" :helperText="`(Additional $${pricing.SPANISH_ACTION_PLANS.toLocaleString('en')})`" chipText="Spanish Add-On">
      <p class="text-sm font-medium">Note: We do not provide Spanish translations for our Canadian market material.</p>
      <p class="text-sm font-medium">Note: After the setup is complete, your review will be required before we can go live with it.</p>
      <p><a class="link underline text-base font-normal" @click="showSpanishContentModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> more info</a></p>
      <SpanishContentModal v-if="showSpanishContentModal" @close="showSpanishContentModal = false" />
      <ToggleSwitch v-model="formModel.includeSpanishActionPlans" />

      <FormSection v-if="formModel.includeSpanishActionPlans" title="Default Language" embed="true">
        <p class="text-sm">Used when language preference is unknown for a lead</p>
        <VRadio :v="v$.formModel.defaultLanguage" :options="['English', 'Spanish']" />
      </FormSection>
    </FormSection>
    <!-- Lender Action Plans -->
    <FormSection title="Include add-on Lender Action Plans?" :helperText="`(Additional $${pricing.LENDER_ADD_ON.toLocaleString('en')})`" chipText="Lender Add-On">
      <p class="text-sm font-medium">Note: We do not provide Spanish translations for lender action plans at this time.</p>
      <p class="text-sm font-medium">Note: By making this selection you agree to the terms that the lenders in your Follow Up Boss account are in fact "Lender" type users. The lender action plans will not work otherwise.</p>
      <ToggleSwitch v-model="formModel.includeLenderAddOn" />

      <FormSection v-if="formModel.includeLenderAddOn" title="Do all lenders in your account work for the same mortgage company?" :embed="true">
        <VRadio :v="v$.formModel.singleMortgageCompany" />
      </FormSection>

      <FormSection v-if="formModel.singleMortgageCompany === 'Yes'" title="Lender Website" :embed="true">
        <VTextField :v="v$.formModel.lenderWebsiteUrl" placeholder="Website URL" />
      </FormSection>

      <FormSection
        v-if="formModel.singleMortgageCompany === 'Yes'"
        title="Online Reviews for Lender"
        helperText="(Please provide any URLs to reviews i.e Google, Facebook etc. Comma-seperated values)"
        :embed="true"
      >
        <VTextField :v="v$.formModel.lenderOnlineReviews" placeholder="URL" />
      </FormSection>
    </FormSection>
    <!-- Team Training -->
    <FormSection title="Private Team Training Call (1 hour)" :helperText="`(Additional $${pricing.PRIVATE_TEAM_TRAINING_CALL.toLocaleString('en')})`" chipText="Private Team Training Add-On">
      <p class="text-sm">One additional training call that your entire team may attend. * All other training calls are limited to two ambassadors from your team.</p>
      <ToggleSwitch v-model="formModel.includePrivateTeamTrainingCall" />
     </FormSection>
    <!-- Prcing -->
    <FormSection title="Project Price">
      <p class="text-xl mt-5">{{ totalDollarAmount }}</p>
      <p class="mt-2"><span class="link underline text-base font-normal" @click="showPriceBreakdownModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see price breakdown</span></p>
    </FormSection>
    <!-- Setup activation notice -->
    <FormSection title="Setup Activation">
      <p class="mt-5">After the setup of your new action plans and automations is complete, we will notify you via email. At this stage, you can either approve immediate activation or opt for a review period to evaluate the setup before it goes live.</p>
    </FormSection>
    <FormSection title="Terms Agreement">
      <div class="mt-5">
        <div class="field" :class="{ 'field-block-error': v$.formModel.agreeToConditions.$error }">
          <p v-if="v$.formModel.agreeToConditions.$error" class="field-error text-xs text-red-500">{{ v$.formModel.agreeToConditions.$errors[0].$message }}</p>
          <input type="checkbox" v-model="formModel.agreeToConditions" true-value="agree" false-value="" />
          <label class="ml-2">I agree to <a class="link text-base font-normal" @click="showConditionAgreement = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> these conditions</a></label>
        </div>
      </div>
     </FormSection>
    <!-- Payment -->
    <FormSection title="Payment">
      <p class="mt-5">Due now is a payment of {{ totalDollarAmount }} USD</p>
      <p class="font-semibold mt-10">Billing Information</p>
      <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
      <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
      <VTextField :v="v$.formModel.billing.email" placeholder="Email" />
      <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
      <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
      <CountrySelect
        v-model="formModel.billing.country"
        :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''"
      />
      <VTextField :v="v$.formModel.billing.city" placeholder="City" />
      <StateSelect
        v-model="formModel.billing.state"
        :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
        :showProvinces="formModel.billing.country === 'Canada'"
      />
      <VTextField :v="v$.formModel.billing.zip" :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
      <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
      <p class="mt-5 font-semibold">Card Information</p>
      <SquarePayment ref="squarePayment" />
      <button @click="submit" :disabled="!canSubmit" class="button mt-5">{{ submitText }}</button>
      <p v-if="v$.$error" class="mt-2 text-red-500">
        Oops... There is an issue with the form. Check fields for errors.
      </p>
    </FormSection>
    <PriceBreakdownModal
      v-if="showPriceBreakdownModal"
      @close="showPriceBreakdownModal = false"
      :lineItems="lineItems"
      :total="total"
    />
    <ConditionAgreementModal v-if="showConditionAgreement" @close="showConditionAgreement = false" />
    <SubmittingModal
      v-if="isSubmitting"
      @close="(isSubmitting = false)"
      :loading="loading"
      :errorMessage="error"
      :showNextSteps="true"
      :receiptUrl="receiptUrl"
    >
      <template #success>
        <div>
          <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
          <p class="text-xl pt-5">Your information has been submitted successfully and a payment of <strong class="font-semibold">{{totalDollarAmount}}</strong> has been processed.</p>
        </div>
      </template>
    </SubmittingModal>
  </FormPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import pricingMixin from '@/mixins/pricingMixin'
import _pick from 'lodash/pick'
import StagesModal from './StagesModal'
import SpanishContentModal from "./SpanishContentModal";
import RevampExtended from '../../common/revamp/RevampExtended.vue';
import { STAGE_SETUP_TYPES } from '../../../constants';

const newWebsiteBackend = {
  url: "",
  username: "",
  password: "",
};
const newWebsite = {
  url: "",
};
const newOnlineReview = {
  url: ""
};
export default {
  mixins: [formValidationMixin, pricingMixin],
  components: { StagesModal, SpanishContentModal, RevampExtended },
  props: ["keeId", "setupType"],
  data() {
    return {
      loading: false,
      error: "",
      isSubmitting: false,
      showConditionAgreement: false,
      showStagesModal: false,
      showSpanishContentModal: false,
      showPriceBreakdownModal: false,
      showRevampExtendedModal: false,
      receiptUrl: "",
      formModel: {
        basicInfo: {
          firstName: "",
          lastName: "",
          email: "",
          phone: ""
        },
        crmUsername: "",
        crmPassword: "",
        websites: [{ ...newWebsite }],
        onlineReviews: [{ ...newOnlineReview }],
        youtube: "",
        brokerage: "",
        businessAddress: {
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        includeSMSDrips: false,
        textingService: "",
        includeLenderAddOn: false,
        singleMortgageCompany: "",
        lenderWebsiteUrl: "",
        lenderOnlineReviews: "",
        multiMarketServicing: "",
        includeRealScoutActionPlans: false,
        includeFelloActionPlans: false,
        includeConversionMonsterActionPlans: false,
        includeRuusterActionPlans: false,
        hasBombBombVideos: "",
        bombbombUsername: "",
        bombbombPassword: "",
        includeYlopoActionPlans: false,
        ylopoUserSearchSiteLive: "",
        ylopoUserBrandedSiteLive: "",
        ylopoUserBrandedSiteUrl: "",
        fubPixelInstalled: "",
        websiteBackends: [{ ...newWebsiteBackend }],
        includeRevampExtended: false,
        includePondsAddOn: false,
        includeSpanishActionPlans: false,
        includePrivateTeamTrainingCall: false,
        defaultLanguage: "",
        stageSetup: "",
        agreeToConditions: "",
        billing: {
          firstName: "",
          lastName: "",
          email: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        },
      },
    };
  },
  validations() {
    const requiredIfNoKeeId = { required: requiredIf(!this.keeId) }
    
    return {
      formModel: {
        basicInfo: {
          firstName: requiredIfNoKeeId,
          lastName: requiredIfNoKeeId,
          email: requiredIfNoKeeId,
          phone: requiredIfNoKeeId
        },
        crmUsername: { required },
        crmPassword: { required },
        youtube: {},
        brokerage: { required },
        businessAddress: {
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
        },
        multiMarketServicing: { required },
        textingService: { required: requiredIf(this.formModel.includeSMSDrips) },
        hasBombBombVideos: { required },
        bombbombUsername: { required: requiredIf(this.formModel.hasBombBombVideos === "Yes") },
        bombbombPassword: { required: requiredIf(this.formModel.hasBombBombVideos === "Yes") },
        ylopoUserSearchSiteLive: { required: requiredIf(this.formModel.includeYlopoActionPlans) },
        ylopoUserBrandedSiteLive: { required: requiredIf(this.formModel.includeYlopoActionPlans) },
        ylopoUserBrandedSiteUrl: { required: requiredIf(this.formModel.ylopoUserBrandedSiteLive === "Yes") },
        fubPixelInstalled: { required },
        stageSetup: { required },
        defaultLanguage: { required: requiredIf(this.formModel.includeSpanishActionPlans) },
        agreeToConditions: { required },
        singleMortgageCompany: { required: requiredIf(this.formModel.includeLenderAddOn) },
        lenderWebsiteUrl: { required: requiredIf(this.formModel.singleMortgageCompany === 'Yes') },
        lenderOnlineReviews: {},
        billing: {
          firstName: { required },
          lastName: { required },
          email: {},
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
          phone: {}
        },
      },
    };
  },
  computed: {
    isDev() {
      return process.env.NODE_ENV !== 'production'
    },
    customizeStages() {
      return this.formModel.stageSetup === STAGE_SETUP_TYPES.CUSTOM
    },
    zillowStages() {
      return this.formModel.stageSetup === STAGE_SETUP_TYPES.ZILLOW_TWO_WAY_SYNC
    },
    canSubmit() {
      return !this.isSubmitting;
    },
    stageSetupTypes() {
      return STAGE_SETUP_TYPES
    },
    customStagesPricing() {
      return this.pricing.CUSTOM_STAGES
    },
    submitText() {
      return `Submit and pay ${this.totalDollarAmount}`
    },
  },
  methods: {
    addWebsite() {
      this.formModel.websites.push({ ...newWebsite });
    },
    removeWebsite(idx) {
      this.formModel.websites.splice(idx, 1);
    },
    addOnlineReview() {
      this.formModel.onlineReviews.push({ ...newOnlineReview });
    },
    removeOnlineReview(idx) {
      this.formModel.onlineReviews.splice(idx, 1);
    },
    addBackend() {
      this.formModel.websiteBackends.push({ ...newWebsiteBackend });
    },
    removeBackend(idx) {
      this.formModel.websiteBackends.splice(idx, 1);
    },
    autoFillForm() {
      this.formModel.basicInfo.firstName = 'Chris'
      this.formModel.basicInfo.lastName = 'Kee'
      this.formModel.basicInfo.email = 'chris@chriskee.com'
      this.formModel.basicInfo.phone = '214-995-4510'
      this.formModel.businessAddress.addressLine1 = '1234 Main St'
      this.formModel.businessAddress.country = 'United States'
      this.formModel.businessAddress.city = 'Flower Mound'
      this.formModel.businessAddress.state = 'Texas'
      this.formModel.businessAddress.zip = '75028'
      this.formModel.hasBombBombVideos = 'No'
      this.formModel.websites = [{ url: 'https://www.test.com' }]
      this.formModel.onlineReviews = [{ url: 'https://www.test.com' }]
      this.formModel.crmUsername = 'test'
      this.formModel.crmPassword = 'test'
      this.formModel.youtube = 'test'
      this.formModel.brokerage = 'test'
      this.formModel.multiMarketServicing = 'No'
      this.formModel.fubPixelInstalled = 'Yes'
      this.formModel.stageSetup = STAGE_SETUP_TYPES.DEFAULT
      this.formModel.agreeToConditions = 'agree'
      this.formModel.billing.firstName = 'Chris'
      this.formModel.billing.lastName = 'Kee'
      this.formModel.billing.email = 'chris@chriskee.com'
      this.formModel.billing.phone = '123-123-1234'
      this.formModel.billing.addressLine1 = '1234 Main St'
      this.formModel.billing.country = 'United States'
      this.formModel.billing.city = 'Flower Mound'
      this.formModel.billing.state = 'Texas'
      this.formModel.billing.zip = '75028'
    },
    buildNotes() {
      const notes = _pick(this.formModel, [
        'websites',
        'onlineReviews',
        'youtube',
        'brokerage',
        'businessAddress',
        'multiMarketServicing',
        'includeRealScoutActionPlans',
        'includeFelloActionPlans',
        'includeConversionMonsterActionPlans',
        'includeYlopoActionPlans',
        'hasBombBombVideos',
        'bombbombUsername',
        'bombbombPassword',
        'ylopoUserSearchSiteLive',
        'ylopoUserBrandedSiteLive',
        'ylopoUserBrandedSiteUrl',
        'fubPixelInstalled',
        'websiteBackends',
        'includeSpanishActionPlans',
        'defaultLanguage',
        'includeLenderAddOn',
        'includeRevampExtended',
        'includePondsAddOn',
        'singleMortgageCompany',
        'lenderWebsiteUrl',
        'lenderOnlineReviews',
        'stageSetup',
        'includeSMSDrips',
        'textingService',
        'includePrivateTeamTrainingCall',
        'includeRuusterActionPlans',
      ])

      return notes
    },
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        const notes = this.buildNotes();
        const billing = this.formModel.billing;
        const businessAddress = this.formModel.businessAddress
        const { locationId, sourceId } = await this.$refs.squarePayment.tokenize();
        this.error = ""
        this.isSubmitting = true
        this.loading = true

        const { receiptUrl } = await this.$service.createSetup({
          basicInfo: !this.keeId ? this.formModel.basicInfo : null,
          keeId: this.keeId || null,
          crmPassword: this.formModel.crmPassword,
          crmUsername: this.formModel.crmUsername,
          notes,
          meta: {
            setupType: this.setupType,
            includeSpanishActionPlans: this.formModel.includeSpanishActionPlans,
            customStages: this.customizeStages,
            includeLenderAddOn: this.formModel.includeLenderAddOn,
            zillowStages: this.zillowStages,
            includeSMSDrips: this.formModel.includeSMSDrips,
            legacyPricing: this.legacyPricing,
            includeRevampExtended: this.formModel.includeRevampExtended,
            includePondsAddOn: this.formModel.includePondsAddOn,
            includeRealScoutActionPlans: this.formModel.includeRealScoutActionPlans,
            includeFelloActionPlans: this.formModel.includeFelloActionPlans,
            includeConversionMonsterActionPlans: this.formModel.includeConversionMonsterActionPlans,
            includeYlopoActionPlans: this.formModel.includeYlopoActionPlans,
            includePrivateTeamTrainingCall: this.formModel.includePrivateTeamTrainingCall,
            includeRuusterActionPlans: this.formModel.includeRuusterActionPlans,
          },
          payment: {
            note: 'FUB Revamp Payment',
            billing,
            locationId,
            sourceId,
          },
          businessAddress
        })

        this.receiptUrl = receiptUrl
        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
  },
};
</script>
<style scoped>
label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>
