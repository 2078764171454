<template>
  <StandardPageLayout :transparentMain="true">
    <div class="text-white text-center my-10">
      <h1 class="text-4xl font-semibold">All set!</h1>
      <h2 class="text-3xl mt-3">We've reserved your appointment.</h2>
      <p class="mt-10 text-xl">Someone from our team will be reaching out to confirm your time.</p>
      <component :is="currentComponent" :demoVideoId="demoVideoId" />
    </div>
  </StandardPageLayout>
</template>
<script>
import { SETUP_TYPE, DEMO_VIDEO } from '../../../constants'
import AgentRevampNextSteps from './next-steps/AgentRevampNextSteps.vue';
import TCAssistNextSteps from './next-steps/TCAssistNextSteps.vue'
export default {
  props: ['setupType'],
  components: { AgentRevampNextSteps, TCAssistNextSteps },
  computed: {
    demoVideoId() {
      return DEMO_VIDEO[this.setupType]
    },
    currentComponent() {
      switch (this.setupType) {
        case SETUP_TYPE.TC_ASSIST: return TCAssistNextSteps
        default: return AgentRevampNextSteps
      }
    }
  }
}
</script>