<template>
  <div class="border-gray-400">
    <p class="flex justify-between items-center font-bold text-lg md:text-xl cursor-pointer py-5 custom-hover transition ease-in-out" :class="{'text-site-purple': showAnswer}" @click="showAnswer = !showAnswer">
      <span>{{ question }}</span>
      <font-awesome-icon class="ml-5" :icon="`fa-solid fa-chevron-${showAnswer ? 'up' : 'down'}`" />
    </p>
    <div v-if="showAnswer" class="my-5 border-l-4 pl-10 border-site-purple">
      <ContentfulRTE :document="answer" class="site-faq-rte-html" />
    </div>
  </div>
</template>
<script>
export default {
  props: ['question', 'answer'],
  data() {
    return {
      showAnswer: false
    }
  }
}
</script>
<style scoped>
.custom-hover:hover {
  @apply text-site-purple;
}
.site-faq-rte-html {
  & ol, ul {
    @apply ml-5 mb-5;
    & p {
      @apply mb-0;
    }
  }
  & p {
    @apply mb-5;
  }
  & a {
    @apply text-site-purple underline cursor-pointer;
  }
}
</style>