<template>
  <div>
    <Modal v-if="isSubmitting">
      <div class="text-center">
        <div v-if="receiptUrl">
          <p class="text-lg">Your payment of ${{groupTrainingExtensionPrice}} has processed and your Office Hours access has been extended 30 days.</p>
          <p class="mt-5">Payment receipt: <a class="link" :href="receiptUrl" target="_blank">{{receiptUrl}}</a></p>
          <button @click="refreshPage" class="button mt-5">View Training</button>
        </div>
        <div v-else-if="errorMessage" class="text-center">
          <p class="text-3xl text-site-purple font-semibold">Something went wrong...</p>
          <p class="text-xl pt-5">There was an isssue submitting your information.</p>
          <p class="texl-lg text-gray-700 mt-5"><span class="bg-gray-200 p-1"><strong>Error Message:</strong> {{errorMessage}}</span></p>
          <p class="mt-5">If the error persists or you need our assistance please contact <MailToLink subject="KTS Office Hours Extension Failure" :body="errorMessage" /></p>
          <button class="button mt-5" @click="closeToRetry">Close</button>
        </div>
        <div v-else class="text-center">
          <p class="text-xl mb-5">Hold tight! Your information is being submitted and payment is processing.</p>
          <font-awesome-icon icon="fa-solid fa-spinner" spin class="text-3xl text-site-purple"/>
        </div>
      </div>
    </Modal>
    <div>
      <h1 class="text-xl">Office Hours Extnesion</h1>
      <p class="mt-2">Submit payment below to extend Office Hours access for 30 days.</p>
      <div class="inline-block bg-white p-5 rounded mt-5">
        <p class="font-semibold mb-5">Total: ${{groupTrainingExtensionPrice}} (USD)</p>
        <p class="font-semibold mt-5">Billing Info</p>
        <VTextField :v="v$.formModel.firstName" placeholder="First Name" />
        <VTextField :v="v$.formModel.lastName" placeholder="Last Name" />
        <VTextField :v="v$.formModel.phone" placeholder="Phone" />
        <VTextField :v="v$.formModel.addressLine1" placeholder="Address Line 1" />
        <VTextField :v="v$.formModel.addressLine2" placeholder="Address Line 2" />
        <CountrySelect
        v-model="formModel.country"
        :errorMessage="v$.formModel.country.$error ? v$.formModel.country.$errors[0].$message : ''"
        />
        <VTextField :v="v$.formModel.city" placeholder="City" />
        <StateSelect
        v-model="formModel.state"
        :errorMessage="v$.formModel.state.$error ? v$.formModel.state.$errors[0].$message : ''"
          :showProvinces="formModel.country === 'Canada'"
        />
        <VTextField :v="v$.formModel.zip" :placeholder="formModel.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
        <p class="mt-5 font-semibold">Card Information</p>
        <SquarePayment ref="squarePayment" />
        <button class="button mt-0 text-base" @click="submit">Submit Payment</button>
      </div>
    </div>
  </div>
</template>
<script>
import { required, formValidationMixin } from '@/mixins/formValidateMixin'
import { GROUP_TRAINING_EXTENSION_PRICE, SETUP_TYPE } from '../../../constants'

export default {
  props: ['keeId', 'setupType'],
  mixins: [formValidationMixin],
  data() {
    return {
      receiptUrl: "",
      errorMessage: "",
      isSubmitting: false,
      formModel: {
        firstName: "",
        lastName: "",
        phone: "",
        addressLine1: "",
        addressLine2: "",
        country: "",
        city: "",
        state: "",
        zip: ""
      }
    }
  },
  validations() {
    return {
      formModel: {
        firstName: { required },
        lastName: { required },
        phone: {},
        addressLine1: { required },
        addressLine2: {},
        country: { required },
        city: { required },
        state: { required },
        zip: { required }
      },
    };
  },
  computed: {
    groupTrainingExtensionPrice() {
      return GROUP_TRAINING_EXTENSION_PRICE
    },
    basePath() {
      switch (this.setupType) {
        case SETUP_TYPE.FUB_AGENT_REVAMP:
        default: return ''
        case SETUP_TYPE.TC_ASSIST: return '/tc-assist'
      }
    },
    paymentNote() {
      switch (this.setupType) {
        case SETUP_TYPE.TC_ASSIST: return 'TC Assist Office Hours - 30 Day Extension'
        case SETUP_TYPE.FUB_AGENT_REVAMP:
        default: return 'FUB Agent Revamp Office Hours - 30 Day Extension'
      }
    }
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
    async submit() {
      if (!(await this.v$.$validate())) return

      this.isSubmitting = true

      let locationId = ""
      let sourceId = ""

      try {
        const paymentToken = await this.$refs.squarePayment.tokenize()
        locationId = paymentToken.locationId
        sourceId = paymentToken.sourceId
      } catch (e) {
        console.error(e.message)
        this.errorMessage = "Oops. Something went wrong"
        return
      }
      
      try {
        const payload = {
          keeId: this.keeId,
          meta: {
            setupType: this.setupType
          },
          payment: {
            note: this.paymentNote,
            locationId,
            sourceId,
            billing: this.formModel
          }
        }

        const { receiptUrl } = await this.$service.extendOfficeHoursAccess(payload)

        this.receiptUrl = receiptUrl

      } catch (e) {
        this.errorMessage = this.$service.getErrorMessage(e)
      }
    },
    closeToRetry() {
      this.errorMessage = "",
      this.isSubmitting = false
    }
  }
}
</script>