<template>
  <TextureBackground>
    <section>
      <div class="page-container">
        <div class="flex flex-col items-center">
          <span class="bg-site-purple px-4 py-1 rounded-full font-semibold">New Workshop!</span>
          <h1 class="text-center border-b-2 border-t-2 p-3 border-kts-gold inline-block mt-3">Enhance Your CRM with Automation</h1>
        </div>
        <div class="mt-10">
          <!-- <p>Tired of Leads Slipping Through Your Fingers?</p> -->
          <p>Are you wasting countless hours on manual tasks, constantly struggling to keep your lead database organized, and feeling like your current CRM is letting you down?</p>
          <p>As a real estate professional, your focus should be on closing deals, not wrestling with an inefficient system that leaves leads cold and opportunities missed.</p>
          <p>Say goodbye to lost leads, confusion, and wasted time. Say hello to streamlined processes, automated campaigns, and higher response rates.</p>
          <p><span class="text-2xl font-medium">Isn't it time your CRM started working for you, instead of the other way around?</span></p>
          <p>Watch Elena's workshop to learn how an automated CRM will help you <span class="text-kts-gold font-semibold text-xl">start meaningful conversations</span>, obtain a <span class="text-kts-gold font-semibold text-xl">realistic daily workflow</span>, and <span class="text-kts-gold font-semibold text-xl">ensure no lead gets lost in your system</span></p>
        </div>
        <h2 class="mt-20 text-center">Watch Elena's CRM Workshop below 👇</h2>
        <div class="text-center text-gray-400 text-base mt-1">Workshop by Elena Kee - Owner of Kee Technology Solutions</div>
        <div class="bg-white md:p-5 mt-5">
          <div class="md:p-5 md:border-2 border-kts-gold">
            <Video videoId="4dcCmKzUUPE" />
          </div>
        </div>
        <div class="mt-10 text-center">
          <h3 class="text-lg mb-5">Looking to revamp your Follow Up Boss?<br>Click that button 👇 to get the ball rolling!</h3>
          <button class="button hover-bright" @click="$refs.signUp.open()">Sign up!</button>
          <h3 class="text-lg mt-10 mb-5">Have more questions about our FUB revamp service?</h3>
          <a class="button hover-bright" target="_blank" :href="callCalendarUrl">Schedule a call</a>
        </div>
      </div>
    </section>
    <section class="bg-site-gray">
      <div class="page-container">
        <div class="lg:grid grid-cols-7 gap-16">
          <div class="lg:col-span-2 self-center flex justify-center">
            <div class="inline-block p-2 bg-red-400 rounded-full max-w-xs">
              <img :src="require(`@/assets/images/elena-headshot.webp`)" />
            </div>
          </div>
          <div class="lg:col-span-5 mt-10 lg:mt-0">
            <h2>Hi, I'm Elena Kee</h2>
            <span class="text-gray-400">Founder of Kee Technology Solutions | Co-founder of BossAssist</span>
            <p>Since I began utilizing Follow Up Boss in 2016, I have thoroughly acquainted myself with its many features. I am well-versed in CRM best practices for realtors.</p>
            <p>Over the years I have served as a guest speaker for Follow Up Boss, speaking at both FUBCON and regional masterminds. Additionally I help moderate the Follow Up Boss Community Success Facebook Group.</p>
            <p>I am the owner of Kee Technology Solutions, a business that provides a turn-key setup in Follow Up Boss that helps solo agents, teams, and brokerages automate, simplify, and streamline their CRM.</p>
            <p>Learn more by visiting <router-link class="text-site-purple font-semibold underline cursor-pointer" to="/">keetechnology.com</router-link></p>
          </div>
        </div>
      </div>
    </section>
    <div class="bg-white p-6">
      <div class="text-center">
        <img  v-for="item in [1,2,3,4,5,6,7,8]" :key="`image_${item}`" :src="require(`@/assets/images/workshop/${item}.png`)" class="inline-block w-32 mx-2" />
      </div>
    </div>
    <Testimonials />
    <footer class="py-8 px-6 text-white">
      <p class="text-center font-medium">© {{$copyrightDate}} by Kee Technology Solutions LLC</p>
    </footer>
    <SignUpForm ref="signUp" :setupType="setupType" />
  </TextureBackground>
</template>
<script>
import TextureBackground from '../common/TextureBackground';
import Testimonials from './home/Testimonials'
import SignUpForm from '../common/landing/SignUpForm';
import { CALENDLY_CALENDARS, CALENDLY_UTM, SETUP_TYPE } from '../../constants';
import { buildCalendarUrl } from '../../utils';

export default {
  components: {
    TextureBackground,
    Testimonials,
    SignUpForm
  },
  computed: {
    setupType() {
      return SETUP_TYPE.FUB_AGENT_REVAMP
    },
    callCalendarUrl() {
      return buildCalendarUrl({
        calendar: CALENDLY_CALENDARS.DISCOVERY,
        utm: CALENDLY_UTM.WORKSHOP_DISCOVERY
      })
    },
  }
}
</script>
<style scoped>
.page-container {
  @apply container mx-auto px-5
}
section {
 @apply py-20 px-6 text-white
}
h1 {
  @apply text-4xl font-semibold
}
h2 {
  @apply text-2xl font-medium
}
p {
  @apply mt-5 text-lg leading-relaxed
}
.button {
  @apply bg-kts-gold text-xl py-4 text-black font-semibold w-64 uppercase
}
</style>